import {
  Avatar,
  Box,
  Button,
  Chip,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Stack,
  Typography
} from '@mui/material'
import React, { useState } from 'react'
import { PageHeader } from 'widgets'
import face from 'shared/assets/joy.jpg'
import { BasicInput, TextEditor } from 'shared/ui'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import { Review } from 'shared/ui/Review'
import teacher from 'shared/assets/teacher.jpg'
import teacher2 from 'shared/assets/teacher2.jpg'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import student from 'shared/assets/student2.png'
import galka from 'shared/assets/free-png.ru-2.png'
import { GROUPS } from 'shared/consts'

function Teacher() {
  const [value, setValue] = useState(dayjs())
  const [groups, setGroups] = useState([GROUPS[0].name, GROUPS[3].name])

  return (
    <>
      <PageHeader
        pageName="О преподователе"
        breadcrumbs={[{ text: 'О преподователе' }]}
        children={
          <Box sx={{ display: 'flex', alignItems: 'start', pt: '25px' }}>
            <Button sx={{ padding: '8px', height: 'fit-content' }} variant="contained">
              Изменить
            </Button>
          </Box>
        }
      />
      <Paper
        sx={{
          borderRadius: '20px',
          height: 'auto',
          overflowX: 'hidden',
          padding: '20px',
          marginBottom: '30px'
        }}
        elevation={24}
      >
        <Box sx={{ padding: '20px' }}>
          <Typography variant="h4" sx={{ paddingBottom: '20px' }}>
            Информация
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '30px',
              marginBottom: '50px',
              justifyContent: 'space-around'
            }}
          >
            <Box sx={{ display: 'flex', gap: '30px', alignItems: 'center' }}>
              <Avatar alt="photo" src={face} sx={{ width: 180, height: 180 }} />
              <Box
                sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '20px' }}
              >
                <Typography variant="h4">Кралин Руслан</Typography>
                <Typography>example@gmail.com</Typography>
                <Typography>
                  <Chip
                    icon={<CheckCircleIcon color="success" />}
                    label="Активен"
                    size="small"
                    variant="outlined"
                  />
                </Typography>
                <Typography
                  sx={{
                    border: '1px solid #0066CC',
                    padding: '5px 10px',
                    borderRadius: '10px',
                    backgroundColor: '#0066CC',
                    color: '#fff'
                  }}
                >
                  Опыт: 5.5 лет
                </Typography>
              </Box>
            </Box>

            <Box>
              <Paper levation={22} sx={{ width: '600px', padding: '20px', borderRadius: '10px' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                  <Typography variant="h5" sx={{ marginRight: '10px' }}>
                    Образование и Сертификаты{' '}
                  </Typography>
                  <img alt="#" src={student} style={{ width: '30px' }} />
                </Box>
                <Typography>
                  {' '}
                  <img alt="#" src={galka} style={{ width: '20px' }} />
                  Курс CELTA , 2019
                </Typography>
                <Typography>
                  {' '}
                  <img alt="#" src={galka} style={{ width: '20px' }} />
                  Курсы для учителей TESOL, 2017
                </Typography>
                <Typography>
                  {' '}
                  <img alt="#" src={galka} style={{ width: '20px' }} />
                  БГЛУ, 2015
                </Typography>
              </Paper>
            </Box>
          </Box>
          {/* <Avatar alt="photo" src={face} sx={{ width: 156, height: 156, marginBottom: '30px' }} /> */}
          <Stack sx={{ marginBottom: '30px', gap: '30px' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '30px' }}>
              <BasicInput label={'Имя и фамилия'} value={'Кралин Руслан'} />

              <BasicInput label={'Телефон'} value={'+375 29 777 77 77'} />
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '30px' }}>
              <BasicInput label={'Email'} value={'example@gmail.com'} />
              <Box sx={{ width: '100%' }}>
                <Typography sx={{ marginBottom: '10px' }}>Дата начала работы</Typography>
                <DatePicker
                  sx={{ width: '100%', borderColor: 'red', fontSize: '50px' }}
                  // label="Controlled picker"
                  value={value}
                  onChange={(newValue) => setValue(newValue)}
                />
              </Box>
            </Box>
          </Stack>
          <Stack sx={{ mb: '30px', gap: '10px' }}>
            <InputLabel id="test-select-label">Группы</InputLabel>

            <Select
              labelId="test-select-label"
              id="test-select"
              multiple
              value={groups}
              onChange={(e) =>
                setGroups(typeof e.target.value === 'string' ? value.split(',') : e.target.value)
              }
              input={<OutlinedInput label="Name" />}
            >
              {GROUPS.map((group) => (
                <MenuItem key={group.id} value={group.name}>
                  {group.name}
                </MenuItem>
              ))}
            </Select>
          </Stack>
          <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '30px' }}>
            <TextEditor label="Вводный текст" />
            <TextEditor label="Контент" />
          </Box>
        </Box>
      </Paper>

      <Paper elevation={22} sx={{ width: '100%', padding: '20px', borderRadius: '20px' }}>
        <Typography sx={{ marginBottom: '20px', fontSize: '1.2rem' }}>Отзывы учеников:</Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
          <Review
            name="Анна Васильева"
            email="example@gmail.com"
            date="21.07.2023"
            photo={teacher}
          />
          <Review name="Елена Смирнова" email="example@gmail.com" date="02.08.2019" />
          <Review
            name="Томара Петровна"
            email="example@gmail.com"
            date="01.03.2017"
            photo={teacher2}
          />
        </Box>
      </Paper>
    </>
  )
}

export default function TeacherWithLocalizationProvider() {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Teacher />
    </LocalizationProvider>
  )
}
