import { makeAutoObservable } from 'mobx'
import { ADMIN_DATA, TEACHER_DATA } from 'shared/consts'

class AuthorizationModel {
  rootStore

  isAuthorized = false
  isAdmin = false

  constructor(rootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  async signIn(values) {
    if (values.email === ADMIN_DATA.login && values.password === ADMIN_DATA.password) {
      this.isAuthorized = true
      this.isAdmin = true
    } else if (values.email === TEACHER_DATA.login && values.password === TEACHER_DATA.password) {
      this.isAuthorized = true
      this.isAdmin = false
    }

    return { isAuthorized: this.isAuthorized, isAdmin: this.isAdmin }
  }
}

export default AuthorizationModel
