import React from 'react'
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Grid,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import { Edit, Delete } from '@mui/icons-material'

import { PageHeader } from 'widgets'

import AttendanceTable from './AttendanceTable'
import { Link } from 'react-router-dom'
import { ROUTES } from 'shared/consts'

function Gradebook() {
  const HOME_WORKS = [
    { id: 1, date: '2024-6-3', text: 'SB p 2-3 WB p 40-41', progress: 10 },
    { id: 2, date: '2024-6-3', text: 'SB p 2-3 WB p 40-41', progress: 20 },
    { id: 3, date: '2024-6-3', text: 'SB p 2-3 WB p 40-41', progress: 30 },
    { id: 4, date: '2024-6-3', text: 'SB p 2-3 WB p 40-41', progress: 40 },
    { id: 5, date: '2024-6-3', text: 'SB p 2-3 WB p 40-41', progress: 50 },
    { id: 6, date: '2024-6-3', text: 'SB p 2-3 WB p 40-41', progress: 60 },
    { id: 7, date: '2024-6-3', text: 'SB p 2-3 WB p 40-41', progress: 70 }
  ]
  return (
    <>
      <PageHeader
        pageName="Журнал СBA"
        breadcrumbs={[{ text: 'Журнал' }]}
        children={
          <Box sx={{ display: 'flex', alignItems: 'start', pt: '25px' }}>
            <Button sx={{ padding: '8px', height: 'fit-content' }} variant="contained">
              Редактировать
            </Button>
          </Box>
        }
      />

      <Stack gap="30px">
        <AttendanceTable />
        <Stack gap={1}>
          <Typography variant="h6">Домашнее задание:</Typography>

          <Grid container spacing={2}>
            {HOME_WORKS.reverse().map((assignment, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card variant="outlined">
                  <CardContent>
                    <Typography variant="h6" component="div">
                      {assignment.date}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {assignment.text}
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginTop: '1rem'
                      }}
                    >
                      <Button variant="contained" component={Link} to={ROUTES.QUIZ}>
                        Start Quiz
                      </Button>
                      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <IconButton aria-label="edit" sx={{ color: '#003A78' }}>
                          <Edit />
                        </IconButton>
                        <IconButton aria-label="delete" color="error">
                          <Delete />
                        </IconButton>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Stack>
      </Stack>
    </>
  )
}

export default Gradebook
