import * as React from 'react'
import { BarChart } from '@mui/x-charts/BarChart'
import { axisClasses } from '@mui/x-charts/ChartsAxis'

const chartSetting = {
  yAxis: [{}],
  width: 1000,
  height: 500,
  sx: {
    [`.${axisClasses.left} .${axisClasses.label}`]: {
      transform: 'translate(-20px, 0)'
    }
  }
}
const dataset = [
  {
    group: 59,
    session: 30,
    students: 200,
    teachers: 21,
    month: 'Январь'
  },
  {
    group: 50,
    session: 52,
    students: 78,
    teachers: 28,
    month: 'Февраль'
  },
  {
    group: 47,
    session: 53,
    students: 106,
    teachers: 41,
    month: 'Март'
  },
  {
    group: 54,
    session: 56,
    students: 92,
    teachers: 73,
    month: 'Апрель'
  },
  {
    group: 57,
    session: 69,
    students: 92,
    teachers: 99,
    month: 'Май'
  },
  {
    group: 60,
    session: 63,
    students: 103,
    teachers: 144,
    month: 'Июнь'
  },
  {
    group: 59,
    session: 60,
    students: 105,
    teachers: 19,
    month: 'Июль'
  },
  {
    group: 65,
    session: 60,
    students: 106,
    teachers: 249,
    month: 'Август'
  },
  {
    group: 51,
    session: 51,
    students: 95,
    teachers: 131,
    month: 'Сентябрь'
  },
  {
    group: 60,
    session: 65,
    students: 97,
    teachers: 55,
    month: 'Октябрь'
  },
  {
    group: 67,
    session: 64,
    students: 76,
    teachers: 48,
    month: 'Ноябрь'
  },
  {
    group: 61,
    session: 70,
    students: 103,
    teachers: 25,
    month: 'Декабрь'
  }
]

const valueFormatter = (value) => `${value}`

function SessionChart() {
  return (
    <BarChart
      dataset={dataset}
      xAxis={[{ scaleType: 'band', dataKey: 'month' }]}
      series={[
        { dataKey: 'group', label: 'Групп', valueFormatter },
        { dataKey: 'session', label: 'Сессий', valueFormatter },
        { dataKey: 'students', label: 'Студентов', valueFormatter },
        { dataKey: 'teachers', label: 'Учителей', valueFormatter }
      ]}
      {...chartSetting}
    />
  )
}

export default SessionChart
