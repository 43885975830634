import { observer } from 'mobx-react-lite'
import React from 'react'

import { Layout } from 'app/Layout'
import {
  Analytics,
  SignIn,
  Teachers,
  Students,
  Student,
  Gradebooks,
  StudentsReports,
  Semesters,
  Teacher,
  Quiz
} from 'pages'

import {
  Navigate,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements
} from 'react-router-dom'
import { ROUTES } from 'shared/consts'

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path={ROUTES.SIGN_IN} element={<SignIn />} />
      <Route path={ROUTES.DEFAULT} element={<Layout />}>
        <Route path={ROUTES.ANALYTICS} element={<Analytics />} />
        <Route path={ROUTES.TEACHERS} element={<Teachers />} />
        <Route path={ROUTES.TEACHER} element={<Teacher />} />

        <Route path={ROUTES.STUDENTS} element={<Students />} />
        <Route path={ROUTES.STUDENT} element={<Student />} />
        <Route path={ROUTES.STUDENTS_REPORTS} element={<StudentsReports />} />

        <Route path={ROUTES.SEMESTERS} element={<Semesters />} />

        <Route path={ROUTES.GRADEBOOKS} element={<Gradebooks />} />

        <Route path={ROUTES.QUIZ} element={<Quiz />} />
        <Route path="/" element={<Navigate to={ROUTES.SIGN_IN} replace />} />
      </Route>
    </>
  )
)

function TeacherRoutes() {
  return <RouterProvider router={router} />
}

export default observer(TeacherRoutes)
