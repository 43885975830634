import { Avatar, Box, Link } from '@mui/material'
import React from 'react'

function stringToColor(string) {
  let hash = 0
  let i

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = '#'

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value.toString(16)}`.slice(-2)
  }
  /* eslint-enable no-bitwise */

  return color
}

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name)
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`
  }
}

function PersonInfo({ id, name, email, photo, href }) {
  return (
    <Box sx={{ display: 'flex', gap: '20px' }}>
      <Box>{photo ? <Avatar alt="photo" src={photo} /> : <Avatar {...stringAvatar(name)} />}</Box>
      <Box>
        <Box>
          <Link underline="hover" sx={{ color: 'black', cursor: 'pointer' }} href={href}>
            {name}
          </Link>
        </Box>
        <Box sx={{ color: 'grey' }}>{email}</Box>
      </Box>
    </Box>
  )
}

export default PersonInfo
