import { createContext, useContext } from 'react'

import AuthorizationModel from 'models/Authorization'

export class RootStore {
  authorization

  constructor() {
    this.authorization = new AuthorizationModel(this)
  }
}

const rootStore = new RootStore()

const rootStoreContext = createContext(rootStore)

export const RootStoreProvider = ({ children }) => (
  <rootStoreContext.Provider value={rootStore}>{children}</rootStoreContext.Provider>
)
export const useRootStore = () => useContext(rootStoreContext)

export default rootStore
