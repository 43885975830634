import React, { useEffect, useState } from 'react'
import { Box, Button, CircularProgress, IconButton, Paper, Stack, Typography } from '@mui/material'

import ClearIcon from '@mui/icons-material/Clear'

function PaymentPopUp({ setIsPaymentPopUpOpen }) {
  const [isLoading, setIsLoading] = useState(false)
  const [isRequestSend, setIsRequestSend] = useState(false)

  function textForSuccesRequest() {
    setIsLoading(true)
    setTimeout(() => {
      setIsLoading(false)
    }, 2000)
    setIsRequestSend(true)
  }

  return (
    <Stack
      component={Paper}
      elevation={11}
      sx={{
        position: 'fixed',
        top: '40%',
        left: '40%',
        p: '24px',
        gap: '30px',
        zIndex: 2,
        width: '600px'
      }}
    >
      {!isLoading && (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <IconButton onClick={() => setIsPaymentPopUpOpen(false)}>
            <ClearIcon />
          </IconButton>
        </Box>
      )}

      {!isLoading && !isRequestSend && (
        <Stack gap="20px">
          <Typography variant="body1">
            Вы уверены что хотите отправить уведомление об оплате?
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button onClick={() => textForSuccesRequest()}>Да</Button>
            <Button onClick={() => setIsPaymentPopUpOpen(false)} color="error">
              Нет
            </Button>
          </Box>
        </Stack>
      )}

      {isLoading && (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress />
        </Box>
      )}

      {!isLoading && isRequestSend && (
        <Typography variant="body1" color="succes">
          Уведомления успешно отправлены!
        </Typography>
      )}
    </Stack>
  )
}

export default PaymentPopUp
