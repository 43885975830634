import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs
} from '@mui/material'
import GroupsIcon from '@mui/icons-material/Groups'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt'

import { PageHeader } from 'widgets'
import { Info, TableSearchFilter } from 'shared/ui'
import { GROUPS, STUDENTS } from 'shared/consts/data'
import { ROUTES } from 'shared/consts'
import { PaymentPopUp } from './ui'

function Students() {
  const [isStudentsActive, setIsStudentsActive] = useState(0)
  const [studentsData, setStudentsData] = useState(STUDENTS)
  const [isBtnDeleteActive, setIsBtnDeleteActive] = useState(0)
  const [isPaymentPopUpOpen, setIsPaymentPopUpOpen] = useState(false)
  const [activeFilter, setActiveFilter] = useState(0)

  const handleChange = (_, newValue) => {
    setIsStudentsActive(newValue)
  }

  useEffect(() => {
    if (isStudentsActive) {
      setStudentsData(STUDENTS.filter((student) => student.active))
    } else {
      setStudentsData(STUDENTS)
    }
  }, [isStudentsActive])

  return (
    <>
      <PageHeader
        pageName="Студенты"
        breadcrumbs={[{ text: 'Студенты' }]}
        children={
          <Box sx={{ display: 'flex', alignItems: 'start', pt: '25px' }}>
            <Button sx={{ padding: '8px', height: 'fit-content' }} variant="contained">
              Добавить
            </Button>
          </Box>
        }
      />

      {isPaymentPopUpOpen && <PaymentPopUp setIsPaymentPopUpOpen={setIsPaymentPopUpOpen} />}

      <TableContainer
        component={Paper}
        elevation={1}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          borderRadius: '20px'
        }}
      >
        <Tabs value={isStudentsActive} onChange={handleChange}>
          <Tab icon={<GroupsIcon />} label="Все" />
          <Tab icon={<CheckCircleIcon />} iconPosition="start" label="Активные" />
        </Tabs>

        <Divider />

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '16px 24px'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px'
            }}
          >
            <TableSearchFilter
              id={1}
              label="Поиск по фамилии"
              name="Фамилия"
              activeFilter={activeFilter}
              setActiveFilter={setActiveFilter}
            />
            <TableSearchFilter
              id={2}
              label="Поиск по почте"
              name="Почта"
              activeFilter={activeFilter}
              setActiveFilter={setActiveFilter}
            />
            <TableSearchFilter
              id={3}
              label="Поиск по телефону"
              name="Телефон"
              activeFilter={activeFilter}
              setActiveFilter={setActiveFilter}
            />
            <TableSearchFilter
              id={4}
              name="Группа"
              label="Группа"
              data={GROUPS}
              type="select"
              activeFilter={activeFilter}
              setActiveFilter={setActiveFilter}
            />
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center', gap: '30px' }}>
            <Button
              onClick={() => setIsPaymentPopUpOpen(true)}
              variant="contained"
              sx={{ textTransform: 'initial' }}
              size="small"
            >
              Отправить уведомление об оплате
            </Button>

            {isBtnDeleteActive > 0 && (
              <Button
                variant="contained"
                sx={{ bgcolor: 'red', textTransform: 'initial' }}
                size="small"
              >
                {`Удалить ${isBtnDeleteActive} студент-(а)`}
              </Button>
            )}
          </Box>
        </Box>

        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Имя и фамилия</TableCell>
              <TableCell>Группа</TableCell>
              <TableCell>Телефон</TableCell>
              <TableCell>Статус</TableCell>
              <TableCell align="left">Отчёт успеваемости</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {studentsData.map((student) => (
              <TableRow>
                <TableCell>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox
                      onClick={(event) =>
                        setIsBtnDeleteActive((prev) => (event.target.checked ? prev + 1 : prev - 1))
                      }
                    />
                    <Info
                      name={`${student.name} ${student.middle_name}`}
                      email={student.email}
                      href={ROUTES.STUDENT}
                    />
                  </Box>
                </TableCell>
                <TableCell>{student.group.name}</TableCell>
                <TableCell>{student.phone_number}</TableCell>
                <TableCell>
                  {student.active ? (
                    <Chip
                      icon={<CheckCircleIcon color="success" />}
                      label="Активен"
                      size="small"
                      variant="outlined"
                    />
                  ) : (
                    <Chip
                      icon={<DoNotDisturbAltIcon color="error" />}
                      label="Не активен"
                      size="small"
                      variant="outlined"
                    />
                  )}
                </TableCell>
                <TableCell align="center">
                  <Button
                    variant="contained"
                    size="small"
                    sx={{
                      bgcolor: '#003A78',
                      display: 'flex',
                      justifySelf: 'flex-end',
                      textTransform: 'inherit'
                    }}
                  >
                    Создать отчёт об успеваемости
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default observer(Students)
