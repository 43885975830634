import { Container, Paper, Box, Alert } from '@mui/material'
import s from './Styles.module.css'
import logo from 'shared/assets/Logo.svg'

import { BackButton } from '../BackButton'

function AuthWrapper({ children, showBackButton }) {
  return (
    <Box
      sx={{
        background: () =>
          `linear-gradient(45deg, rgba(14, 19, 32) 30%, rgba(37, 69, 125, 1) 80%, rgba(3, 175, 213, 1) 100%)`
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          gap: 15
        }}
      >
        <img src={logo} className={s.logo} alt="Logo" />
        {/* <Typography variant="h3">LOGO</Typography> */}

        <Box>
          {showBackButton && <BackButton />}
          <Paper
            elevation={16}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              minWidth: 550,
              p: 3
            }}
          >
            {children}
          </Paper>
          <Alert severity="error" sx={{ mt: 2.5 }}>
            Вы можете использовать логин: <strong>&quot;admin@gmail.com&quot;</strong> и пароль:{' '}
            <strong>&quot;qwesd&quot;</strong>
          </Alert>
        </Box>
      </Container>
    </Box>
  )
}

export default AuthWrapper
