import {
  Avatar,
  Box,
  Button,
  Chip,
  Divider,
  // Input,
  Paper,
  // TextField,
  Typography
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { GRADES, QUARTERS, STUDENTS } from 'shared/consts/data'
import { PageHeader } from 'widgets'
import face from 'shared/assets/roy.jpg'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import PermIdentityIcon from '@mui/icons-material/PermIdentity'
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined'
import { Review } from 'shared/ui/Review'
import teacher from 'shared/assets/teacher.jpg'
import teacher2 from 'shared/assets/teacher2.jpg'
import { CustomSelect } from 'shared/ui/CustomSelect'
import { BasicInput } from 'shared/ui'
// import { TextareaAutosize } from '@mui/base/TextareaAutosize'
import TextArea from 'shared/ui/TextArea/TextArea'
import { ProgressItem } from './ui/ProgressItem'

import goal from 'shared/assets/goal3.png'

const TEACHERS = [
  { id: 1, name: 'Николай Васильевич' },

  { id: 2, name: 'Тамара Петровна' },

  { id: 3, name: 'Александр Русланович' },

  { id: 4, name: 'Иван Сергеевич' },
  { id: 5, name: 'Елена Валерьевна' }
]

function Student() {
  // const [student, setStudent] = useState({})
  const { id } = useParams()

  // useEffect(() => {
  //   setStudent(STUDENTS.find((item) => item.id === +id))
  // }, [id])

  return (
    <>
      <PageHeader
        pageName="Студент"
        breadcrumbs={[{ text: 'Студент' }]}
        children={
          <Box sx={{ display: 'flex', alignItems: 'start', pt: '25px' }}>
            <Button sx={{ padding: '8px', height: 'fit-content' }} variant="contained">
              Редактировать
            </Button>
          </Box>
        }
      />

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '30px',
            marginBottom: '50px',
            justifyContent: 'space-around'
          }}
        >
          <Box sx={{ display: 'flex', gap: '30px', alignItems: 'center' }}>
            <Avatar alt="photo" src={face} sx={{ width: 180, height: 180 }} />
            <Box
              sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px' }}
            >
              <Typography variant="h4">Кралин Руслан</Typography>
              <Typography>example@gmail.com</Typography>
              <Typography>
                <Chip
                  icon={<CheckCircleIcon color="success" />}
                  label="Активен"
                  size="small"
                  variant="outlined"
                />
              </Typography>
            </Box>
          </Box>

          <Box>
            <Paper levation={22} sx={{ width: '600px', padding: '20px', borderRadius: '10px' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                <Typography variant="h5" sx={{ marginRight: '10px' }}>
                  Прогресс{' '}
                </Typography>
                <img alt="#" src={goal} style={{ width: '30px' }} />
              </Box>

              <ProgressItem
                level="Upper-Intermediate"
                color="white"
                // procent={'87%'}
                width={'100%'}
                text={'В процессе...'}
              />
              <ProgressItem
                level="Intermediate (18.02.2023)"
                color="green"
                procent={'95%'}
                width={'95%'}
              />
              <ProgressItem
                level="Pre-Intermediate (17.06.2021)"
                color="#FFC700"
                procent={'60%'}
                width={'60%'}
              />
              <ProgressItem
                level="Elementary (26.12.2020)"
                color="#06D001"
                procent={'70%'}
                width={'70%'}
              />
              <ProgressItem
                level="Beginner (20.05.2019)"
                color="#EE4E4E"
                procent={'49%'}
                width={'49%'}
              />
            </Paper>
          </Box>
        </Box>

        <Box sx={{ display: 'flex', gap: '30px' }}>
          <Paper elevation={22} sx={{ width: '33%', padding: '20px', borderRadius: '10px' }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: '50px'
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <PermIdentityIcon sx={{ width: '50px', height: '50px', marginRight: '20px' }} />
                <Typography>Общая информация</Typography>
              </Box>
              <Box>
                <CreateOutlinedIcon />
              </Box>
            </Box>

            <Box>
              <Typography sx={{ color: 'grey', marginBottom: '10px' }}>Имя и фамилия</Typography>
              <Typography>Руслан Кралин</Typography>
              <Divider sx={{ width: '100%', marginBottom: '30px' }} />
            </Box>

            <Box>
              <Typography sx={{ color: 'grey', marginBottom: '10px' }}>Телефон</Typography>
              <Typography>+375 29 777 77 77</Typography>
              <Divider sx={{ width: '100%', marginBottom: '30px' }} />
            </Box>

            <Box>
              <Typography sx={{ color: 'grey', marginBottom: '10px' }}>Почта</Typography>
              <Typography>example@gmail.com</Typography>
              <Divider sx={{ width: '100%', marginBottom: '30px' }} />
            </Box>

            <Box>
              <Typography sx={{ color: 'grey', marginBottom: '10px' }}>Группа</Typography>
              <Typography>С1</Typography>
              <Divider sx={{ width: '100%', marginBottom: '30px' }} />
            </Box>
          </Paper>
          <Paper
            elevation={22}
            sx={{
              width: '66%',
              padding: '20px',
              borderRadius: '10px'
            }}
          >
            <Box>
              <Box sx={{ marginBottom: '30px' }}>
                <Typography variant="h5">Отчёт успеваемости по текущему курсу</Typography>
              </Box>
              <Box
                sx={{
                  display: ' flex',
                  justifyContent: 'space-between',
                  marginBottom: '20px',
                  gap: '20px'
                }}
              >
                <Box sx={{ width: '50%' }}>
                  <CustomSelect label={'Преподаватель'} data={TEACHERS} width={420} />
                </Box>
                <Box sx={{ width: '50%' }}>
                  <CustomSelect label={'Четверть/Семестр'} data={QUARTERS} width={420} />
                </Box>
              </Box>

              <Box>
                <Typography sx={{ fontWeight: '700' }}>Говорение</Typography>
              </Box>

              <Box
                sx={{
                  display: ' flex',
                  justifyContent: 'space-between',
                  gap: '10px',
                  marginBottom: '20px'
                }}
              >
                <Box sx={{ width: '33%' }}>
                  <CustomSelect label={'Беглость'} data={GRADES} />
                </Box>
                <Box sx={{ width: '33%' }}>
                  <CustomSelect label={'Грамматика'} data={GRADES} />
                </Box>
                <Box sx={{ width: '33%' }}>
                  <CustomSelect label={'Произношение'} data={GRADES} />
                </Box>
              </Box>

              <Box
                sx={{
                  display: ' flex',
                  justifyContent: 'space-between',
                  gap: '10px',
                  marginBottom: '20px'
                }}
              >
                <Box sx={{ width: '33%' }}>
                  <BasicInput label={'Количество письменных работ'} value={5} />
                </Box>
                <Box sx={{ width: '33%' }}>
                  <BasicInput label={'Выполнено письменных работ'} value={3} />
                </Box>
                <Box sx={{ width: '33%' }}>
                  <CustomSelect label={'Средняя Оценка'} data={GRADES} />
                </Box>
              </Box>

              <Box
                sx={{
                  display: ' flex',
                  justifyContent: 'space-between',
                  gap: '10px',
                  marginBottom: '20px'
                }}
              >
                <Box sx={{ width: '33%' }}>
                  <BasicInput label={'Лексический тест, %'} value={80} />
                </Box>
                <Box sx={{ width: '33%' }}>
                  <BasicInput label={'Грамматический тест, %'} value={85} />
                </Box>
                <Box sx={{ width: '33%' }}>
                  <BasicInput label={'Количество пропущенных занятий'} value={2} />
                </Box>
              </Box>
            </Box>

            <Box>
              <Typography sx={{ marginBottom: '10px', color: 'grey' }}>
                Комментарии и рекомендации
              </Typography>
              <TextArea />
            </Box>
          </Paper>
        </Box>
        <Paper elevation={22} sx={{ width: '100%', padding: '20px', borderRadius: '10px' }}>
          <Typography sx={{ marginBottom: '20px', fontSize: '1.2rem' }}>
            Отзывы перподователей:
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
            <Review
              name="Анна Васильева"
              email="example@gmail.com"
              date="21.07.2023"
              photo={teacher}
            />
            <Review name="Елена Смирнова" email="example@gmail.com" date="02.08.2019" />
            <Review
              name="Томара Петровна"
              email="example@gmail.com"
              date="01.03.2017"
              photo={teacher2}
            />
          </Box>
        </Paper>
      </Box>
    </>
  )
}

export default Student
