export const ROUTES = {
  DEFAULT: '/',
  SIGN_IN: '/sign-in',
  ANALYTICS: '/analytics',
  TEACHERS: '/teachers',
  TEACHER: '/teacher',

  STUDENTS: '/students',
  STUDENT: '/student',
  STUDENTS_REPORTS: '/students/reports',

  SEMESTERS: '/semesters',
  SEMESTER: '/semester',

  GRADEBOOKS: '/gradebooks',
  GRADEBOOK: '/gradebook',

  REPORTS: '/reports',

  QUIZ: '/quiz'
}
