import { useState } from 'react'
import * as yup from 'yup'
import { Formik } from 'formik'
import { Button, Stack, Typography, Box } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

import { InputField } from 'shared/ui'
import { ROUTES } from 'shared/consts'
import { useRootStore } from 'stores'
import { emailValidation, passwordValidation } from 'shared/validations'

import { AuthWrapper } from './ui'

function SignIn() {
  const [error, setError] = useState(false)
  const { authorization } = useRootStore()

  const navigate = useNavigate()

  const validationSchema = yup.object().shape({
    email: emailValidation(),
    password: passwordValidation()
  })

  return (
    <AuthWrapper>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} mb={3}>
        <Typography variant="h5">Вход</Typography>
      </Box>
      <Formik
        initialValues={{
          email: '',
          password: ''
        }}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          await authorization.signIn(values)
          console.log('пщ')
          navigate(ROUTES.ANALYTICS)
        }}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit} style={{ width: '100%' }}>
            <Stack justifyContent="center" spacing={2}>
              <InputField field="email" label="Логин" />
              <InputField field="password" label="Пароль" type="password" />
              {error && (
                <Typography color="error" variant="error">
                  Неверные данные входа
                </Typography>
              )}
              <Button variant="contained" type="submit" sx={{ bgcolor: '#003a78' }}>
                Войти
              </Button>
            </Stack>
          </form>
        )}
      </Formik>
    </AuthWrapper>
  )
}

export default observer(SignIn)
