import React, { useState } from 'react'
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'

import { PageHeader } from 'widgets'
import { ROUTES } from 'shared/consts'
import { TableSearchFilter } from 'shared/ui'
import { GROUPS, OFFICES, SEMESTERS } from 'shared/consts/data'

function Semesters() {
  const [isBtnDeleteActive, setIsBtnDeleteActive] = useState(0)
  const [activeFilter, setActiveFilter] = useState(0)
  const navigate = useNavigate()

  return (
    <>
      <PageHeader
        pageName="Семестры"
        breadcrumbs={[{ text: 'Семестры' }]}
        children={
          <Box sx={{ display: 'flex', alignItems: 'start', pt: '25px' }}>
            <Button sx={{ padding: '8px', height: 'fit-content' }} variant="contained">
              Добавить
            </Button>
          </Box>
        }
      />

      <TableContainer
        component={Paper}
        elevation={1}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          borderRadius: '20px'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '16px 24px'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px'
            }}
          >
            <TableSearchFilter
              id={1}
              label="Поиск по названию семестра"
              name="Семестр"
              activeFilter={activeFilter}
              setActiveFilter={setActiveFilter}
            />
            <TableSearchFilter
              id={2}
              name="Офис"
              label="Офиса"
              data={OFFICES}
              type="select"
              activeFilter={activeFilter}
              setActiveFilter={setActiveFilter}
            />
            <TableSearchFilter
              id={3}
              name="Группа"
              label="Группа"
              data={GROUPS}
              type="select"
              activeFilter={activeFilter}
              setActiveFilter={setActiveFilter}
            />
          </Box>

          {isBtnDeleteActive > 0 && (
            <Button
              variant="contained"
              sx={{ bgcolor: 'red', textTransform: 'initial' }}
              size="small"
            >
              {`Удалить ${isBtnDeleteActive} семестр-(а)`}
            </Button>
          )}
        </Box>

        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Группы</TableCell>
              <TableCell>Период</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {SEMESTERS.map((semester) => (
              <TableRow key={semester.id}>
                <TableCell>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox
                      onClick={(event) =>
                        setIsBtnDeleteActive((prev) => (event.target.checked ? prev + 1 : prev - 1))
                      }
                    />
                    <Typography
                      onClick={() => navigate(ROUTES.SEMESTER)}
                      sx={{ textTransform: 'inherit', mt: '1px', cursor: 'pointer' }}
                    >
                      {semester.group.name}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>{semester.period}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default Semesters
