// import { Avatar, Box, Link } from '@mui/material'
import { Box, Typography } from '@mui/material'
import React from 'react'
import { PersonInfo } from '../PersonInfo'

function Review({ id, name, email, photo, date }) {
  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <PersonInfo name={name} email={email} photo={photo} />
        <Typography sx={{ color: 'grey' }}>{date}</Typography>
      </Box>
      <Box>
        <Typography>
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Labore, sapiente corporis. Rerum
          inventore aliquid necessitatibus blanditiis. Architecto, qui. Nam natus dolores nesciunt
          facilis fugit quaerat voluptatem sunt corporis illum ipsa?
        </Typography>
      </Box>
    </>
  )
}

export default Review
