import React, { useState } from 'react'
import { Box, Button, MenuItem, Paper, Select, TextField, Typography } from '@mui/material'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'

function TableSearchFilter({
  label,
  name,
  data,
  type = 'input',
  id,
  setActiveFilter,
  activeFilter
}) {
  // const [isSearchWindowOpen, setIsSearchWindowOpen] = useState(false)
  const [value, setValue] = useState(1)

  const handleChange = (event) => {
    setValue(event.target.value)
  }

  return (
    <Box sx={{ zIndex: 1 }}>
      <Button
        onClick={() => {
          if (activeFilter === id) {
            setActiveFilter(0)
          } else {
            setActiveFilter(id)
          }
          // setIsSearchWindowOpen(!isSearchWindowOpen)
        }}
        variant="outlined"
        startIcon={<AddCircleOutlineIcon color="#32383e" />}
        sx={{
          color: '#32383e',
          borderColor: '#dde7ee',
          textTransform: 'capitalize',
          position: 'relative',
          '&:hover': {
            borderColor: '#dde7ee'
          }
        }}
      >
        {name}
      </Button>

      {type === 'input' && activeFilter === id && (
        <Paper
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            position: 'absolute',
            padding: '16px',
            marginTop: '8px'
          }}
        >
          <Typography variant="subtitle2">{label}</Typography>
          <TextField size="small" label={name} />
          <Button sx={{ bgcolor: '#003a78' }} variant="contained">
            Применить
          </Button>
        </Paper>
      )}

      {type === 'select' && activeFilter === id && (
        <Paper
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            position: 'absolute',
            padding: '16px',
            marginTop: '8px'
          }}
        >
          <Typography variant="subtitle2">{label}</Typography>
          <Select value={value} label={label} onChange={handleChange}>
            {data.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </Paper>
      )}
    </Box>
  )
}

export default TableSearchFilter
