import React from 'react'
import { Outlet } from 'react-router-dom'
import { Box, Container } from '@mui/material'

import { NavBar } from 'widgets'
import { ROUTES } from 'shared/consts'

function Layout() {
  return (
    <Box
      sx={{
        display: 'flex',
        backgroundColor: '#fff',
        height: '100%'
      }}
    >
      <Box
        sx={{
          width: '260px',
          minHeight: '100vh'
        }}
      >
        <Box sx={{ height: '100%', overflow: 'auto' }}>
          <NavBar />
        </Box>
      </Box>
      <Container
        component="main"
        maxWidth={ROUTES.TEACHERS || ROUTES.STUDENTS ? 'xl' : 'lg'}
        sx={{
          flexGrow: 1,
          py: 7,
          px: 4
          // width: { xs: `calc(100% - ${DRAWER_WIDTH}px)` }
        }}
      >
        <Outlet />
      </Container>
    </Box>
  )
}

export default Layout
