// import { Avatar, Box, Link } from '@mui/material'
import * as React from 'react'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { Box, Typography } from '@mui/material'

function CustomSelect({ data, label }) {
  const [value, setValue] = React.useState(1)

  const handleChange = (event) => {
    setValue(event.target.value)
  }
  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {' '}
        <Typography sx={{ marginBottom: '10px', color: 'grey' }}>{label}</Typography>
        <FormControl
          sx={{
            width: '100%',
            '& .MuiOutlinedInput-root': {
              '&.Mui-focused fieldset': {
                border: '2px solid #212636'
              }
            }
          }}
        >
          <Select
            value={value}
            onChange={handleChange}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
          >
            {data.map((i) => (
              <MenuItem value={i.id}>{i.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </>
  )
}

export default CustomSelect
