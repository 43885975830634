import React from 'react'
import { Box, Divider, Paper, Stack, Typography } from '@mui/material'

import TrendingUpIcon from '@mui/icons-material/TrendingUp'
import TrendingDownIcon from '@mui/icons-material/TrendingDown'

function NewsCard({ mainIcon, title, number, percent, text, trending }) {
  return (
    <Paper
      sx={{ display: 'flex', flexDirection: 'column', width: '30%', borderRadius: '20px' }}
      elevation={2}
    >
      <Box sx={{ p: '16px 24px 32px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'raw', alignItems: 'center', gap: '24px' }}>
          {mainIcon}
          <Stack sx={{ gap: '5px' }}>
            <Typography variant="body1" color="#667085" fontWeight={500}>
              {title}
            </Typography>
            <Typography variant="h4" color="#212636">
              {number}
            </Typography>
          </Stack>
        </Box>
      </Box>

      <Divider />

      <Box sx={{ p: '16px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'raw', alignItems: 'center', gap: '10px' }}>
          {trending ? (
            <TrendingUpIcon color="success" sx={{ w: '20px', h: '20px' }} />
          ) : (
            <TrendingDownIcon color="error" sx={{ w: '20px', h: '20px' }} />
          )}
          <Typography
            variant="body2"
            sx={{ display: 'flex', flexDirection: 'raw', alignItems: 'center', gap: '10px' }}
            color="#667085"
          >
            <Typography variant="subtitle2" color={trending ? '#15b79f' : '#f04438'}>
              {percent}
            </Typography>
            {text}
          </Typography>
        </Box>
      </Box>
    </Paper>
  )
}

export default NewsCard
