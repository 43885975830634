import { Box, Typography } from '@mui/material'
import React from 'react'

function ProgressItem({ level, color, procent, width, text }) {
  return (
    <>
      <Box sx={{ marginBottom: '10px' }}>
        <Typography sx={{ color: 'grey', fontSize: '0.875rem' }}>{level}</Typography>
        <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
          <Box
            sx={{
              height: '20px',
              border: `1px solid ${color}`,
              borderRadius: '10px',
              backgroundColor: `${color}`,
              width: `${width}`
            }}
          >
            {text}
          </Box>

          <Typography>{procent}</Typography>
        </Box>
      </Box>
    </>
  )
}

export default ProgressItem
