import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Box } from '@mui/material'
import { ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material'
import { Link, useLocation } from 'react-router-dom'
import { SiGoogleanalytics } from 'react-icons/si'
import { IoPerson } from 'react-icons/io5'
import { SlEarphonesAlt } from 'react-icons/sl'
import { LuCalendarRange } from 'react-icons/lu'
import { MdDashboard } from 'react-icons/md'
import { TbFileReport } from 'react-icons/tb'

import { ROUTES } from 'shared/consts'
import logo from 'shared/assets/Logo.svg'

import style from './styles.module.css'

const listItem = [
  {
    icon: <SiGoogleanalytics />,
    title: 'Аналитика',
    link: ROUTES.ANALYTICS
  },
  {
    icon: <IoPerson />,
    title: 'Преподаватели',
    link: ROUTES.TEACHERS
  },
  {
    icon: <SlEarphonesAlt />,
    title: 'Студенты',
    link: ROUTES.STUDENTS
  },
  {
    icon: <LuCalendarRange />,
    title: 'Семестры',
    link: ROUTES.SEMESTERS
  },
  {
    icon: <MdDashboard />,
    title: 'Журналы',
    link: ROUTES.GRADEBOOKS
  },
  {
    icon: <TbFileReport />,
    title: 'Отчёты',
    link: ROUTES.STUDENTS_REPORTS
  }
]

function NavBar() {
  const [activeItem, setActiveItem] = useState(null)
  const { pathname } = useLocation()

  useEffect(() => {
    listItem.forEach((item, id) => {
      if (item.link === pathname) {
        setActiveItem(id)
      }
    })
  }, [])

  const handleClick = (index) => {
    setActiveItem(index)
  }

  return (
    <Box sx={{ background: '#212636', height: '100%', overflowY: 'hidden' }}>
      <img src={logo} className={`${style.logo} logo`} alt="Logo" />
      <Box sx={{ margin: '20px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          {listItem.map((item, index) => (
            <ListItemButton
              component={Link}
              to={item.link}
              key={item.title}
              sx={{
                py: 0.6,
                px: 1,
                borderRadius: '10px',
                bgcolor: activeItem === index ? '#003a78!important' : 'inherit',
                ':hover': {
                  bgcolor: 'rgba(255, 255, 255, 0.04)',
                  '& .item-text': {
                    color: '#fff!important'
                  }
                }
              }}
              onClick={() => handleClick(index)}
              selected={activeItem === index}
            >
              <ListItemIcon
                sx={{
                  color: activeItem === index ? '#fff' : 'rgb(160, 174, 192)',
                  fontSize: 20
                }}
              >
                {item.icon}
              </ListItemIcon>
              <ListItemText>
                <Typography
                  className="item-text"
                  sx={{
                    fontSize: '0.875',
                    fontWeight: '600',
                    ':hover': {
                      color: '#fff!important'
                    }
                  }}
                  color={activeItem === index ? '#fff' : '#B3B9C6'}
                >
                  {item.title}
                </Typography>
              </ListItemText>
            </ListItemButton>
          ))}
        </Box>
      </Box>
    </Box>
  )
}

export default observer(NavBar)
