import React from 'react'
import './AttendanceTable.css'

const AttendanceTable = () => {
  const rows = [
    {
      name: 'Feshchenko Dmitriy',
      '03.06': '✔️',
      '04.06': '/',
      '05.06': '/',
      '06.06': '/',
      10.06: '/',
      11.06: '/',
      12.06: '/',
      13.06: '/'
    },
    {
      name: 'Gerasimenko Elena',
      '03.06': '/',
      '04.06': '/',
      '05.06': '/',
      '06.06': '/',
      10.06: '/',
      11.06: '/',
      12.06: '/',
      13.06: '/'
    },
    {
      name: 'Kunevich Polina',
      '03.06': '/',
      '04.06': '/',
      '05.06': '/',
      '06.06': '/',
      10.06: '/',
      11.06: '/',
      12.06: '/',
      13.06: '/'
    },
    {
      name: 'Meleshko Aleksandra',
      '03.06': '/',
      '04.06': '/',
      '05.06': '/',
      '06.06': '/',
      10.06: '/',
      11.06: '/',
      12.06: '/',
      13.06: '/'
    },
    {
      name: 'Petrochenko Ruslan',
      '03.06': '/',
      '04.06': '/',
      '05.06': '/',
      '06.06': '/',
      10.06: '/',
      11.06: '/',
      12.06: '/',
      13.06: '/'
    },
    {
      name: 'Rybinskaya Ekaterina',
      '03.06': '/',
      '04.06': '/',
      '05.06': '/',
      '06.06': '/',
      10.06: '/',
      11.06: '/',
      12.06: '/',
      13.06: '/'
    },
    {
      name: 'Seleznev Artem',
      '03.06': '/',
      '04.06': '/',
      '05.06': '/',
      '06.06': '/',
      10.06: '/',
      11.06: '/',
      12.06: '/',
      13.06: '/'
    },
    {
      name: 'Zmitrachnyonok Yana',
      '03.06': '/',
      '04.06': '/',
      '05.06': '/',
      '06.06': '/',
      10.06: '/',
      11.06: '/',
      12.06: '/',
      13.06: '/'
    }
  ]

  return (
    <div className="attendance-container">
      <p>Teacher: Olga Karpovich</p>
      <p>Books: IH Complete Beginners</p>
      <p>Period: 2024-06-03 - 2024-07-05</p>
      <table>
        <thead>
          <tr>
            <th rowSpan="2">CBA</th>
            <th colSpan="4">Week 1</th>
            <th colSpan="4">Week 2</th>
            <th rowSpan="2">Week 3</th>
            <th rowSpan="2">Week 4</th>
            <th rowSpan="2">Week 5</th>
            <th colSpan="3">Test</th>
          </tr>
          <tr>
            <th>03.06</th>
            <th>04.06</th>
            <th>05.06</th>
            <th>06.06</th>
            <th>10.06</th>
            <th>11.06</th>
            <th>12.06</th>
            <th>13.06</th>
            <th>Mark</th>
            <th>%</th>
            <th>Next level/certif.</th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row) => (
            <tr key={row.name}>
              <td>{row.name}</td>
              <td>{row['03.06']}</td>
              <td>{row['04.06']}</td>
              <td>{row['05.06']}</td>
              <td>{row['06.06']}</td>
              <td>{row['10.06']}</td>
              <td>{row['11.06']}</td>
              <td>{row['12.06']}</td>
              <td>{row['13.06']}</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="legend">
        <p>✔️ - был на занятии</p>
        <p>z/ - подключился на занятие через Zoom</p>
        <p>+/ - был на занятии и сделал домашнее задание</p>
        <p>+/- был на занятии и не сделал домашнее задание</p>
        <p>/L - опоздал на занятие</p>
        <p>0 - отсутствовал на занятии</p>
      </div>
    </div>
  )
}

export default AttendanceTable
