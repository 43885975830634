import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import LinearProgress, {
  LinearProgressProps,
  linearProgressClasses
} from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { Button, Chip, Paper } from '@mui/material'
import { MdOutlineArrowBackIos } from 'react-icons/md'
import { MdArrowForwardIos } from 'react-icons/md'
// import { englishTestQuestions } from 'shared/consts/data'
import icons8 from 'shared/assets/free-icon-thumb-up-3713311.png'

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 20,
  borderRadius: 7,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800]
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#06D001' : '#308fe8'
  }
}))

const englishTestQuestions = [
  {
    step: 10,
    question: "What is the past tense of 'go'?",
    options: ['goed', 'went', 'gone', 'going'],
    correctAnswer: 'went'
  },
  {
    step: 20,
    question: "Which word is a synonym for 'happy'?",
    options: ['sad', 'angry', 'joyful', 'tired'],
    correctAnswer: 'joyful'
  },
  {
    step: 30,
    question: "What is the plural form of 'child'?",
    options: ['childs', 'children', 'childes', 'childrens'],
    correctAnswer: 'children'
  },
  {
    step: 40,
    question: 'Which word is an adjective?',
    options: ['run', 'quickly', 'beautiful', 'jump'],
    correctAnswer: 'beautiful'
  },
  {
    step: 50,
    question: "Choose the correct form: 'She ___ to the store.'",
    options: ['goed', 'going', 'went', 'goes'],
    correctAnswer: 'went'
  },
  {
    step: 60,
    question: "What is the antonym of 'hot'?",
    options: ['warm', 'cold', 'cool', 'heat'],
    correctAnswer: 'cold'
  },
  {
    step: 70,
    question: "Complete the sentence: 'They ___ playing soccer.'",
    options: ['is', 'are', 'was', 'were'],
    correctAnswer: 'are'
  },
  {
    step: 80,
    question: 'Which sentence is correct?',
    options: [
      "She don't like apples.",
      "She doesn't likes apples.",
      "She doesn't like apples.",
      'She not like apples.'
    ],
    correctAnswer: "She doesn't like apples."
  },
  {
    step: 90,
    question: "What is the comparative form of 'big'?",
    options: ['biggest', 'biger', 'more big', 'bigger'],
    correctAnswer: 'bigger'
  },
  {
    step: 100,
    question: 'Which word is a noun?',
    options: ['run', 'beautiful', 'happiness', 'quickly'],
    correctAnswer: 'happiness'
  }
]

const wrongAnswers = [
  {
    step: 80,
    question: 'Which sentence is correct?',
    options: [
      "She don't like apples.",
      "She doesn't likes apples.",
      "She doesn't like apples.",
      'She not like apples.'
    ],
    correctAnswer: "She doesn't like apples."
  },
  {
    step: 90,
    question: "What is the comparative form of 'big'?",
    options: ['biggest', 'biger', 'more big', 'bigger'],
    correctAnswer: 'bigger'
  },
  {
    step: 100,
    question: 'Which word is a noun?',
    options: ['run', 'beautiful', 'happiness', 'quickly'],
    correctAnswer: 'happiness'
  }
]

const getQuestion = (step) => {
  return englishTestQuestions.find((q) => q.step === step)
}

function Quiz() {
  const [step, setStep] = useState(10)
  const [question, setQuestion] = useState(getQuestion(10))
  const [result, setResult] = useState(false)

  useEffect(() => {
    setQuestion(getQuestion(step))
  }, [step])

  const handleNext = () => {
    if (step < 100) setStep(step + 10)
  }

  const handlePrevious = () => {
    if (step > 10) setStep(step - 10)
  }

  return (
    <>
      {!result && (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '100px' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
              <BorderLinearProgress variant="determinate" value={step} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
              <Typography>{`${step}%`}</Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '150px'
            }}
          >
            <Box>
              <Typography variant="h2">{question.question}</Typography>
            </Box>

            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '40px' }}>
              {question.options.map((i) => (
                <Chip
                  component={Button}
                  onClick={() => setStep(step + 10)}
                  variant="outlined"
                  label={i}
                  color="primary"
                  sx={{
                    width: '300px',
                    height: '70px',
                    fontSize: '30px',
                    flexBasis: 'calc(50% - 20px)',
                    marginBottom: '20px'
                  }}
                />
              ))}
            </Box>
            {step !== 100 && (
              <Box sx={{ display: 'flex', gap: '3rem' }}>
                <Button
                  onClick={() => handlePrevious()}
                  variant="text"
                  sx={{ width: '200px', height: '50px' }}
                  startIcon={<MdOutlineArrowBackIos />}
                >
                  Previus
                </Button>
                <Button
                  onClick={() => handleNext()}
                  variant="text"
                  sx={{ width: '200px', height: '50px' }}
                  endIcon={<MdArrowForwardIos />}
                >
                  Next
                </Button>
              </Box>
            )}
          </Box>
          {step === 100 && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '100px'
              }}
            >
              <Button
                onClick={() => setResult(true)}
                variant="contained"
                color="success"
                sx={{ width: '200px', height: '100px', fontSize: '50px' }}
              >
                Check
              </Button>
            </Box>
          )}
        </>
      )}
      {result && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography
              variant="h2"
              sx={{ color: 'green', fontWeight: '700', padding: '50px 10px' }}
            >
              Your Score is: 80%
            </Typography>
            <img
              src={icons8}
              alt="#"
              style={{ width: '100px', height: '120px', marginBottom: '1rem' }}
            />
          </Box>
          <icons8 />
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '100px',
              flexDirection: 'column',
              gap: '30px'
            }}
          >
            <Paper elevation={24} sx={{ width: '100%', borderColor: 'red' }}>
              <Box sx={{ padding: '50px' }}>
                <Typography variant="h2">What is the comparative form of 'big'?</Typography>
              </Box>

              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '40px', padding: '50px' }}>
                <Chip
                  variant="outlined"
                  label={'biggest'}
                  color="primary"
                  sx={{
                    // width: '100px',
                    borderColor: 'red',
                    height: '70px',
                    fontSize: '30px',
                    flexBasis: 'calc(50% - 20px)',
                    marginBottom: '20px'
                  }}
                />
                <Chip
                  variant="outlined"
                  label={'biger'}
                  color="primary"
                  sx={{
                    // width: '100px',
                    borderColor: 'white',
                    height: '70px',
                    fontSize: '30px',
                    flexBasis: 'calc(50% - 20px)',
                    marginBottom: '20px'
                  }}
                />
                <Chip
                  variant="outlined"
                  label={'more big'}
                  color="primary"
                  sx={{
                    // width: '100px',
                    borderColor: 'white',
                    height: '70px',
                    fontSize: '30px',
                    flexBasis: 'calc(50% - 20px)',
                    marginBottom: '20px'
                  }}
                />
                <Chip
                  variant="outlined"
                  label={'bigger'}
                  color="primary"
                  sx={{
                    // width: '100px',
                    borderColor: 'green',
                    height: '70px',
                    fontSize: '30px',
                    flexBasis: 'calc(50% - 20px)',
                    marginBottom: '20px'
                  }}
                />
              </Box>
            </Paper>

            <Paper elevation={24} sx={{ width: '100%', borderColor: 'red' }}>
              <Box sx={{ padding: '50px' }}>
                <Typography variant="h2">Which word is a noun?</Typography>
              </Box>

              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '40px', padding: '50px' }}>
                <Chip
                  variant="outlined"
                  label={'run'}
                  color="primary"
                  sx={{
                    // width: '100px',
                    borderColor: 'white',
                    height: '70px',
                    fontSize: '30px',
                    flexBasis: 'calc(50% - 20px)',
                    marginBottom: '20px'
                  }}
                />
                <Chip
                  variant="outlined"
                  label={'beautiful'}
                  color="primary"
                  sx={{
                    // width: '100px',
                    borderColor: 'white',
                    height: '70px',
                    fontSize: '30px',
                    flexBasis: 'calc(50% - 20px)',
                    marginBottom: '20px'
                  }}
                />
                <Chip
                  variant="outlined"
                  label={'happiness'}
                  color="primary"
                  sx={{
                    // width: '100px',
                    borderColor: 'green',
                    height: '70px',
                    fontSize: '30px',
                    flexBasis: 'calc(50% - 20px)',
                    marginBottom: '20px'
                  }}
                />
                <Chip
                  variant="outlined"
                  label={'quickly'}
                  color="primary"
                  sx={{
                    // width: '100px',
                    borderColor: 'red',
                    height: '70px',
                    fontSize: '30px',
                    flexBasis: 'calc(50% - 20px)',
                    marginBottom: '20px'
                  }}
                />
              </Box>
            </Paper>
          </Box>
        </Box>
      )}
    </>
  )
}

export default Quiz
