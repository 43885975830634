import React, { useState } from 'react'
import { EditorState } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { Box, Typography } from '@mui/material'
import s from './Style.module.css'

const TextEditor = ({ label }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty())

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState)
  }

  return (
    <Box>
      <Typography sx={{ marginBottom: '20px', fontWeight: '700' }}>{label}</Typography>
      <Box className={s.editor_container}>
        <Editor
          editorState={editorState}
          toolbarClassName={s.editor_toolbar}
          wrapperClassName={s.editor_wrapper}
          editorClassName={s.editor_content}
          onEditorStateChange={onEditorStateChange}
          toolbar={{
            options: [
              'inline',
              'blockType',
              'fontSize',
              'fontFamily',
              'list',
              'textAlign',
              'colorPicker',
              'link',
              'embedded',
              'emoji',
              'image',
              'remove',
              'history'
            ],
            inline: { inDropdown: false },
            list: { inDropdown: false },
            textAlign: { inDropdown: false },
            link: { inDropdown: false },
            history: { inDropdown: false }
          }}
        />
      </Box>
    </Box>
  )
}

export default TextEditor
