import React, { useState } from 'react'
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'

import { PageHeader } from 'widgets'
import { TableSearchFilter } from 'shared/ui'
import { GROUPS, OFFICES, STUDENTS_REPORTS } from 'shared/consts/data'
import DeleteIcon from '@mui/icons-material/Delete'
import { Link } from 'react-router-dom'
import { ROUTES } from 'shared/consts'

function StudentsReports() {
  const [isBtnDeleteActive, setIsBtnDeleteActive] = useState(0)
  const [activeFilter, setActiveFilter] = useState(0)

  return (
    <>
      <PageHeader
        pageName="Отчеты об успеваемости"
        breadcrumbs={[{ text: 'Отчеты об успеваемости' }]}
        children={
          <Box sx={{ display: 'flex', alignItems: 'start', pt: '25px' }}>
            <Button sx={{ padding: '8px', height: 'fit-content' }} variant="contained">
              Добавить
            </Button>
          </Box>
        }
      />

      <TableContainer
        component={Paper}
        elevation={1}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          borderRadius: '20px'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '16px 24px'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px'
            }}
          >
            <TableSearchFilter
              id={1}
              label="Поиск по названию журнала"
              name="Журнал"
              activeFilter={activeFilter}
              setActiveFilter={setActiveFilter}
            />
            <TableSearchFilter
              id={2}
              name="Офис"
              label="Офиса"
              data={OFFICES}
              type="select"
              activeFilter={activeFilter}
              setActiveFilter={setActiveFilter}
            />
            <TableSearchFilter
              id={3}
              name="Группа"
              label="Группа"
              data={GROUPS}
              type="select"
              activeFilter={activeFilter}
              setActiveFilter={setActiveFilter}
            />
          </Box>

          {isBtnDeleteActive > 0 && (
            <Button
              variant="contained"
              sx={{ bgcolor: 'red', textTransform: 'initial' }}
              size="small"
            >
              {`Удалить ${isBtnDeleteActive} отчёт-(а)`}
            </Button>
          )}
        </Box>

        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ФИО</TableCell>
              <TableCell>Группа</TableCell>
              <TableCell>Четверть</TableCell>
              <TableCell align="left">Действия</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {STUDENTS_REPORTS.map((report) => (
              <TableRow key={report.id}>
                <TableCell>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox
                      onClick={(event) =>
                        setIsBtnDeleteActive((prev) => (event.target.checked ? prev + 1 : prev - 1))
                      }
                    />
                    <Typography
                      component={Link}
                      to={ROUTES.STUDENT}
                      sx={{
                        textTransform: 'inherit',
                        mt: '1px',
                        color: 'black',
                        textDecoration: 'none'
                      }}
                    >
                      {`${report.name} ${report.middle_name}`}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>{report.group.name}</TableCell>
                <TableCell>{report.group.name}</TableCell>
                <TableCell align="left">
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      gap: '10px'
                    }}
                  >
                    <Button
                      variant="text"
                      sx={{ color: '#003A78', pl: '0', pr: '10px', textTransform: 'initial' }}
                    >
                      Скачать
                    </Button>
                    <Button
                      variant="contained"
                      sx={{ bgcolor: '#003A78', textTransform: 'initial', mr: '10px' }}
                    >
                      Редактировать
                    </Button>
                    <Button variant="contained" color="error">
                      <DeleteIcon />
                    </Button>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default StudentsReports
