import { Box, Button, Chip, MenuItem, Paper, Select, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import { PageHeader } from 'widgets'
import { BasicInput } from 'shared/ui'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import 'react-quill/dist/quill.snow.css'
import s from './Style.module.css'
import { WEEK } from 'shared/consts'

function Semester() {
  const [value, setValue] = useState(dayjs())
  const [value2, setValue2] = useState(dayjs())
  const [dayAndTime, setDayAndTime] = useState([])
  return (
    <>
      <PageHeader
        pageName="О преподователе"
        breadcrumbs={[{ text: 'О преподователе' }]}
        children={
          <Box sx={{ display: 'flex', alignItems: 'start', pt: '25px' }}>
            <Button sx={{ padding: '8px', height: 'fit-content' }} variant="contained">
              Редактировать
            </Button>
          </Box>
        }
      />
      <Paper sx={{ borderRadius: '20px', height: '1000px', overflow: 'hidden' }} elevation={24}>
        <Box sx={{ padding: '20px', display: 'flex', flexDirection: 'column', gap: '20px' }}>
          <Typography variant="h4" sx={{ paddingBottom: '20px' }}>
            Информация
          </Typography>

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '50px' }}>
              <BasicInput label={'Офис'} value={'Машерова 533'} />
              <BasicInput label={'Группа'} value={'А1'} />
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '50px' }}>
              {' '}
              <Box sx={{ width: '100%' }}>
                <Typography sx={{ marginBottom: '10px' }}>Начало семестра</Typography>
                <DatePicker
                  className={s.datePicker}
                  sx={{
                    width: '100%',
                    '& .MuiOutlinedInput-root': {
                      '&.Mui-focused fieldset': {
                        border: '2px solid #212636'
                      }
                    }
                  }}
                  value={value}
                  onChange={(newValue) => setValue(newValue)}
                />
              </Box>
              <Box sx={{ width: '100%' }}>
                <Typography sx={{ marginBottom: '10px' }}>Окончание семестра</Typography>
                <DatePicker
                  className={s.datePicker}
                  sx={{
                    width: '100%',
                    '& .MuiOutlinedInput-root': {
                      '&.Mui-focused fieldset': {
                        border: '2px solid #212636'
                      }
                    }
                  }}
                  value={value2}
                  onChange={(newValue) => setValue2(newValue)}
                />
              </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '50px' }}>
              {' '}
              <BasicInput label={'Преподаватель'} value={'Кралин Антон'} />
              <BasicInput label={'Преподаватель 2'} value={'Кралин Руслан'} />
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '50px' }}>
              {' '}
              <BasicInput label={'Zoom link'} value={'Ссылка на zoom'} />
              <BasicInput label={'Book link'} value={'Ссылка на книгу'} />
            </Box>
            {/* <Box sx={{ display: 'flex', alignItems: 'center', gap: '50px' }}></Box> */}

            <Paper elevation={11} sx={{ p: '16px' }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '50px',
                  width: '100%'
                }}
              >
                <Stack sx={{ justifyContent: 'space-between', width: '100%', gap: '10px' }}>
                  <Typography variant="body1">День</Typography>

                  <Select
                    variant="filled"
                    value={1}
                    label="День"
                    sx={{ width: '100%', bgcolor: '#fff' }}
                  >
                    {WEEK.map((day) => (
                      <MenuItem key={day.id} value={day.id}>
                        {day.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Stack>

                <BasicInput value={'12:00'} label={'Время'} />
              </Box>
              <Button sx={{ mt: '20px' }} size="small" variant="contained">
                Добавить
              </Button>

              <Stack sx={{ mt: '20px', gap: '10px' }}>
                <Typography variant="h6" marginBottom="5px">
                  {' '}
                  День и время:
                </Typography>

                <Typography variant="body1"> Понедельник 14-00</Typography>
                <Typography variant="body1"> Среда 16-30</Typography>
                <Typography variant="body1"> Пятница 18-00</Typography>
              </Stack>
            </Paper>
          </Box>
        </Box>
      </Paper>
    </>
  )
}

export default function SemesterWithLocalizationProvider() {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Semester />
    </LocalizationProvider>
  )
}
