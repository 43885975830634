import {
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { GROUPS, ROUTES, TEACHERS } from 'shared/consts'
import { PageHeader } from 'widgets'
import { Info, TableSearchFilter } from 'shared/ui'
import GroupsIcon from '@mui/icons-material/Groups'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt'

function Teachers() {
  const [value, setValue] = useState(0)
  const [teachers, setTeachers] = useState(TEACHERS)
  const [isShowActiveTeachersActive, setIsShowActiveTeachersActive] = useState(false)
  const [isBtnDeleteActive, setIsBtnDeleteActive] = useState(0)
  const [activeFilter, setActiveFilter] = useState(0)

  useEffect(() => {
    if (isShowActiveTeachersActive) {
      setTeachers(teachers.filter((tecaher) => tecaher.active))
    } else {
      setTeachers(TEACHERS)
    }
  }, [isShowActiveTeachersActive])

  const handleChange = (_, newValue) => {
    setValue(newValue)
  }
  return (
    <>
      <PageHeader
        pageName="Преподователи"
        breadcrumbs={[{ text: 'Преподователи' }]}
        children={
          <Box sx={{ display: 'flex', alignItems: 'start', pt: '25px' }}>
            <Button sx={{ padding: '8px', height: 'fit-content' }} variant="contained">
              Добавить
            </Button>
          </Box>
        }
      />

      <TableContainer
        component={Paper}
        elevation={1}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          borderRadius: '20px'
        }}
      >
        <Tabs value={value} onChange={handleChange}>
          <Tab
            icon={<GroupsIcon />}
            label="Все"
            component={Button}
            onClick={() => setIsShowActiveTeachersActive(false)}
          />
          <Tab
            icon={<CheckCircleIcon />}
            iconPosition="start"
            label="Активные"
            component={Button}
            onClick={() => setIsShowActiveTeachersActive(true)}
          />
        </Tabs>

        <Divider />

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '16px 24px'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px'
            }}
          >
            <TableSearchFilter
              id={1}
              label="Поиск по фамилии"
              name="ФИО"
              activeFilter={activeFilter}
              setActiveFilter={setActiveFilter}
            />
            <TableSearchFilter
              id={2}
              label="Поиск по почте"
              name="Почта"
              activeFilter={activeFilter}
              setActiveFilter={setActiveFilter}
            />
            <TableSearchFilter
              id={3}
              label="Поиск по телефону"
              name="Телефон"
              activeFilter={activeFilter}
              setActiveFilter={setActiveFilter}
            />
            <TableSearchFilter
              id={4}
              name="Группа"
              label="Группа"
              data={GROUPS}
              type="select"
              activeFilter={activeFilter}
              setActiveFilter={setActiveFilter}
            />
          </Box>
          {isBtnDeleteActive > 0 && (
            <Button
              variant="contained"
              sx={{ bgcolor: 'red', textTransform: 'initial' }}
              size="small"
            >
              {`Удалить ${isBtnDeleteActive} учитель-(я)`}
            </Button>
          )}
        </Box>

        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell align="left">Имя и фамилия</TableCell>
              <TableCell align="left">Телефон</TableCell>
              <TableCell align="left">Группы</TableCell>
              <TableCell align="left">Активный</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {teachers.map((i) => (
              <TableRow key={i.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row" sx={{ display: 'flex' }}>
                  <Checkbox
                    onClick={(event) =>
                      setIsBtnDeleteActive((prev) => (event.target.checked ? prev + 1 : prev - 1))
                    }
                  />
                  <Info name={i.name} email={i.email} photo={i.photo} href={ROUTES.TEACHER} />
                </TableCell>
                <TableCell align="left">{i.phone}</TableCell>
                <TableCell align="left">{i.group.name}</TableCell>
                <TableCell align="left">
                  {i.active ? (
                    <Chip
                      icon={<CheckCircleIcon color="success" />}
                      label="Активен"
                      size="small"
                      variant="outlined"
                    />
                  ) : (
                    <Chip
                      icon={<DoNotDisturbAltIcon color="error" />}
                      label="Не активен"
                      size="small"
                      variant="outlined"
                    />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default Teachers
