import { observer } from 'mobx-react-lite'
import { AdminRoutes, TeacherRoutes } from './routes'
import './Styles.module.css'

import { RootStoreProvider, useRootStore } from 'stores'

function App() {
  const { authorization } = useRootStore()
  return (
    <div>
      <RootStoreProvider>
        <AdminRoutes />
      </RootStoreProvider>
    </div>
  )
}

export default observer(App)
