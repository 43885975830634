import React from 'react'
import { PageHeader } from 'widgets'
import { Box } from '@mui/material'
import GroupsIcon from '@mui/icons-material/Groups'
import PersonIcon from '@mui/icons-material/Person'

import AssessmentIcon from '@mui/icons-material/Assessment'
import { NewsCard, SessionChart } from './ui'
import { PieChart } from '@mui/x-charts'

function Analytics() {
  const news = [
    {
      mainIcon: <GroupsIcon sx={{ width: 34, height: 34 }} />,
      title: 'Групп',
      number: '64',
      percent: '15%',
      text: 'увеличилось за последний месяц',
      trending: true
    },
    {
      mainIcon: <PersonIcon sx={{ width: 34, height: 34 }} />,
      title: 'Учащихся',
      number: '1600',
      percent: '6%',
      text: 'уменьшилось за последний месяц',
      trending: false
    },
    {
      mainIcon: <AssessmentIcon sx={{ width: 34, height: 34 }} />,
      title: 'Сессий',
      number: '22',
      percent: '18%',
      text: 'увеличилось за последний месяц',
      trending: true
    }
  ]

  return (
    <>
      <PageHeader pageName="Аналитика" breadcrumbs={[{ text: 'Аналитика' }]} />

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '50px' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          {news.map((item) => (
            <NewsCard
              key={item.title}
              mainIcon={item.mainIcon}
              title={item.title}
              number={item.number}
              percent={item.percent}
              text={item.text}
              trending={item.trending}
            />
          ))}
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <SessionChart />

          <PieChart
            series={[
              {
                data: [
                  { id: 0, value: 100, label: 'A1' },
                  { id: 1, value: 55, label: 'A2' },
                  { id: 2, value: 40, label: 'B1' },
                  { id: 3, value: 30, label: 'B2' },
                  { id: 4, value: 20, label: 'C1' }
                ]
              }
            ]}
            width={400}
            height={200}
          />
        </Box>
      </Box>
    </>
  )
}

export default Analytics
