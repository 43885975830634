import ann from '../assets/ann.jpg'
import joy from '../assets/joy.jpg'
import roy from '../assets/roy.jpg'

export const GROUPS = [
  { id: '1', name: 'A1' },
  { id: '2', name: 'A2' },
  { id: '3', name: 'A3' },
  { id: '4', name: 'B1' },
  { id: '5', name: 'B2' },
  { id: '6', name: 'B3' },
  { id: '7', name: 'C1' },
  { id: '8', name: 'C2' },
  { id: '9', name: 'C3' },
  { id: '10', name: 'D1' },
  { id: '11', name: 'D2' },
  { id: '12', name: 'D3' },
  { id: '13', name: 'E1' },
  { id: '14', name: 'E2' },
  { id: '15', name: 'E3' },
  { id: '16', name: 'F1' },
  { id: '17', name: 'F2' },
  { id: '18', name: 'F3' },
  { id: '19', name: 'G1' },
  { id: '20', name: 'G2' },
  { id: '21', name: 'G3' },
  { id: '22', name: 'H1' },
  { id: '23', name: 'H2' },
  { id: '24', name: 'H3' },
  { id: '25', name: 'I1' },
  { id: '26', name: 'I2' },
  { id: '27', name: 'I3' },
  { id: '28', name: 'J1' },
  { id: '29', name: 'J2' },
  { id: '30', name: 'J3' },
  { id: '31', name: 'K1' },
  { id: '32', name: 'K2' },
  { id: '33', name: 'K3' },
  { id: '34', name: 'L1' },
  { id: '35', name: 'L2' },
  { id: '36', name: 'L3' },
  { id: '37', name: 'M1' },
  { id: '38', name: 'M2' },
  { id: '39', name: 'M3' },
  { id: '40', name: 'N1' },
  { id: '41', name: 'N2' },
  { id: '42', name: 'N3' }
]

export const TEACHERS = [
  {
    id: 1,
    name: 'Ivan Petrov',
    email: 'ivan.petrov1@gmail.com',
    phone: '+375 29 456 78 90',
    group: GROUPS[1],
    active: true
  },
  {
    id: 2,
    name: 'Petr Ivanov',
    email: 'petr.ivanov@gmail.com',
    phone: '+375 29 456 78 91',
    group: GROUPS[2],
    active: false
  },
  {
    id: 3,
    name: 'Sergey Sidorov',
    email: 'sergey.sidorov@gmail.com',
    phone: '+375 29 456 78 92',
    group: GROUPS[1],
    active: true
  },
  {
    id: 4,
    name: 'Mikhail Smirnov',
    email: 'mikhail.smirnov@gmail.com',
    phone: '+375 29 456 78 93',
    group: GROUPS[3],
    active: false
  },
  {
    id: 5,
    name: 'Nikolay Kuznetsov',
    email: 'nikolay.kuznetsov@gmail.com',
    phone: '+375 29 456 78 94',
    group: GROUPS[5],
    active: true
  },
  {
    id: 6,
    name: 'Andrey Popov',
    email: 'andrey.popov@gmail.com',
    phone: '+375 29 456 78 95',
    group: GROUPS[2],
    active: false
  },
  {
    id: 7,
    name: 'Dmitry Vasiliev',
    email: 'dmitry.vasiliev@gmail.com',
    phone: '+375 29 456 78 96',
    group: GROUPS[6],
    active: true
  },
  {
    id: 8,
    name: 'Alexander Lebedev',
    email: 'alexander.lebedev@gmail.com',
    phone: '+375 29 456 78 97',
    group: GROUPS[8],
    active: false
  },
  {
    id: 9,
    name: 'Alexey Kozlov',
    email: 'alexey.kozlov@gmail.com',
    phone: '+375 29 456 78 98',
    group: GROUPS[7],
    active: true
  },
  {
    id: 10,
    name: 'Vladimir Morozov',
    email: 'vladimir.morozov@gmail.com',
    phone: '+375 29 456 78 99',
    group: GROUPS[2],
    active: false
  },
  {
    id: 11,
    name: 'Denis Pavlov',
    email: 'denis.pavlov@gmail.com',
    phone: '+375 29 456 78 100',
    group: GROUPS[10],
    active: true
  },
  {
    id: 12,
    name: 'Igor Yashin',
    email: 'igor.yashin@gmail.com',
    phone: '+375 29 456 78 101',
    group: GROUPS[6],
    active: false
  },
  {
    id: 13,
    name: 'Yuri Fedorov',
    email: 'yuri.fedorov@gmail.com',
    phone: '+375 29 456 78 102',
    group: GROUPS[14],
    active: true
  },
  {
    id: 14,
    name: 'Roman Makarov',
    email: 'roman.makarov@gmail.com',
    phone: '+375 29 456 78 103',
    group: GROUPS[14],
    active: false
  },
  {
    id: 15,
    name: 'Anton Egorov',
    email: 'anton.egorov@gmail.com',
    phone: '+375 29 456 78 104',
    group: GROUPS[13],
    active: true
  },
  {
    id: 16,
    name: 'Oleg Borodin',
    email: 'oleg.borodin@gmail.com',
    phone: '+375 29 456 78 105',
    group: GROUPS[15],
    active: false
  }
]

export const STUDENTS = [
  {
    id: 1,
    name: 'Dmitry',
    middle_name: 'Govorko',
    email: 'dmitry.govorko@gmail.com',
    phone_number: '+375 33 618 32 16',
    group: { id: '42', name: 'N3' },
    active: true
  },
  {
    id: 2,
    name: 'Olga',
    middle_name: 'Petrova',
    email: 'olga.petrova@gmail.com',
    phone_number: '+375 44 789 45 12',
    group: { id: '1', name: 'A1' },
    active: false
  },
  {
    id: 3,
    name: 'Ivan',
    middle_name: 'Ivanov',
    email: 'ivan.ivanov@gmail.com',
    phone_number: '+375 29 456 78 90',
    group: { id: '7', name: 'C1' }
  },
  {
    id: 4,
    name: 'Anastasia',
    middle_name: 'Sidorova',
    email: 'anastasia.sidorova@gmail.com',
    phone_number: '+375 25 123 45 67',
    group: { id: '13', name: 'E1' }
  },
  {
    id: 5,
    name: 'Andrey',
    middle_name: 'Sergeev',
    email: 'andrey.sergeev@gmail.com',
    phone_number: '+375 33 987 65 43',
    group: { id: '19', name: 'G1' },
    active: true
  },
  {
    id: 6,
    name: 'Maria',
    middle_name: 'Kuznetsova',
    email: 'maria.kuznetsova@gmail.com',
    phone_number: '+375 44 654 32 10',
    group: { id: '25', name: 'I1' },
    active: true
  },
  {
    id: 7,
    name: 'Alexander',
    middle_name: 'Popov',
    email: 'alexander.popov@gmail.com',
    phone_number: '+375 29 321 54 87',
    group: { id: '31', name: 'K1' },
    active: false
  },
  {
    id: 8,
    name: 'Daria',
    middle_name: 'Sokolova',
    email: 'daria.sokolova@gmail.com',
    phone_number: '+375 25 987 65 43',
    group: { id: '37', name: 'M1' },
    active: false
  },
  {
    id: 9,
    name: 'Maxim',
    middle_name: 'Petrov',
    email: 'maxim.petrov@gmail.com',
    phone_number: '+375 33 456 78 90',
    group: { id: '2', name: 'A2' },
    active: true
  },
  {
    id: 10,
    name: 'Ksenia',
    middle_name: 'Ivanova',
    email: 'ksenia.ivanova@gmail.com',
    phone_number: '+375 44 123 45 67',
    group: { id: '8', name: 'C2' },
    active: true
  },
  {
    id: 11,
    name: 'Pavel',
    middle_name: 'Sidorov',
    email: 'pavel.sidorov@gmail.com',
    phone_number: '+375 29 654 32 10',
    group: { id: '14', name: 'E2' },
    active: true
  },
  {
    id: 12,
    name: 'Ekaterina',
    middle_name: 'Solovyova',
    email: 'ekaterina.solovyova@gmail.com',
    phone_number: '+375 33 321 54 87',
    group: { id: '20', name: 'G2' },
    active: true
  },
  {
    id: 13,
    name: 'Sergey',
    middle_name: 'Kirillov',
    email: 'sergey.kirillov@gmail.com',
    phone_number: '+375 44 987 65 43',
    group: { id: '26', name: 'I2' },
    active: true
  },
  {
    id: 14,
    name: 'Alina',
    middle_name: 'Makarova',
    email: 'alina.makarova@gmail.com',
    phone_number: '+375 25 456 78 90',
    group: { id: '32', name: 'K2' },
    active: true
  },
  {
    id: 15,
    name: 'Artem',
    middle_name: 'Volkov',
    email: 'artem.volkov@gmail.com',
    phone_number: '+375 33 123 45 67',
    group: { id: '38', name: 'M2' },
    active: true
  },
  {
    id: 16,
    name: 'Yuliya',
    middle_name: 'Pavlova',
    email: 'yuliya.pavlova@gmail.com',
    phone_number: '+375 29 654 32 10',
    group: { id: '3', name: 'A3' },
    active: true
  },
  {
    id: 17,
    name: 'Evgeny',
    middle_name: 'Aleksandrov',
    email: 'evgeny.aleksandrov@gmail.com',
    phone_number: '+375 44 321 54 87',
    group: { id: '9', name: 'C3' },
    active: true
  },
  {
    id: 18,
    name: 'Anna',
    middle_name: 'Morozova',
    email: 'anna.morozova@gmail.com',
    phone_number: '+375 25 987 65 43',
    group: { id: '15', name: 'E3' },
    active: false
  },
  {
    id: 19,
    name: 'Timur',
    middle_name: 'Ivanov',
    email: 'timur.ivanov@gmail.com',
    phone_number: '+375 33 456 78 90',
    group: { id: '21', name: 'G3' },
    active: false
  },
  {
    id: 20,
    name: 'Polina',
    middle_name: 'Smirnova',
    email: 'polina.smirnova@gmail.com',
    phone_number: '+375 29 123 45 67',
    group: { id: '27', name: 'I3' },
    active: true
  },
  {
    id: 21,
    name: 'Nikita',
    middle_name: 'Kuznetsov',
    email: 'nikita.kuznetsov@gmail.com',
    phone_number: '+375 44 654 32 10',
    group: { id: '33', name: 'K3' },
    active: true
  },
  {
    id: 22,
    name: 'Yana',
    middle_name: 'Belova',
    email: 'yana.belova@gmail.com',
    phone_number: '+375 25 321 54 87',
    group: { id: '39', name: 'M3' },
    active: true
  },
  {
    id: 23,
    name: 'Kirill',
    middle_name: 'Popov',
    email: 'kirill.popov@gmail.com',
    phone_number: '+375 33 987 65 43',
    group: { id: '4', name: 'A4' },
    active: true
  },
  {
    id: 24,
    name: 'Alisa',
    middle_name: 'Ivanova',
    email: 'alisa.ivanova@gmail.com',
    phone_number: '+375 29 654 32 10',
    group: { id: '10', name: 'C4' },
    active: true
  },
  {
    id: 25,
    name: 'Pyotr',
    middle_name: 'Sokolov',
    email: 'pyotr.sokolov@gmail.com',
    phone_number: '+375 44 321 54 87',
    group: { id: '16', name: 'E4' },
    active: true
  },
  {
    id: 26,
    name: 'Svetlana',
    middle_name: 'Nikonova',
    email: 'svetlana.nikonova@gmail.com',
    phone_number: '+375 25 987 65 43',
    group: { id: '22', name: 'G4' },
    active: false
  },
  {
    id: 27,
    name: 'Alexey',
    middle_name: 'Andreev',
    email: 'alexey.andreev@gmail.com',
    phone_number: '+375 33 456 78 90',
    group: { id: '28', name: 'I4' },
    active: true
  },
  {
    id: 28,
    name: 'Darya',
    middle_name: 'Dmitrieva',
    email: 'darya.dmitrieva@gmail.com',
    phone_number: '+375 29 123 45 67',
    group: { id: '34', name: 'K4' },
    active: false
  },
  {
    id: 29,
    name: 'Roman',
    middle_name: 'Petrov',
    email: 'roman.petrov@gmail.com',
    phone_number: '+375 44 654 32 10',
    group: { id: '40', name: 'M4' },
    active: true
  },
  {
    id: 30,
    name: 'Elizaveta',
    middle_name: 'Sokolova',
    email: 'elizaveta.sokolova@gmail.com',
    phone_number: '+375 25 321 54 87',
    group: { id: '5', name: 'A5' },
    active: true
  },
  {
    id: 31,
    name: 'Denis',
    middle_name: 'Ivanov',
    email: 'denis.ivanov@gmail.com',
    phone_number: '+375 33 987 65 43',
    group: { id: '11', name: 'C5' },
    active: true
  },
  {
    id: 32,
    name: 'Tatyana',
    middle_name: 'Sidorova',
    email: 'tatyana.sidorova@gmail.com',
    phone_number: '+375 29 654 32 10',
    group: { id: '17', name: 'E5' },
    active: true
  },
  {
    id: 33,
    name: 'Vladimir',
    middle_name: 'Sergeev',
    email: 'vladimir.sergeev@gmail.com',
    phone_number: '+375 44 321 54 87',
    group: { id: '23', name: 'G5' },
    active: true
  },
  {
    id: 34,
    name: 'Yuliya',
    middle_name: 'Kuznetsova',
    email: 'yuliya.kuznetsova@gmail.com',
    phone_number: '+375 25 987 65 43',
    group: { id: '29', name: 'I5' },
    active: true
  },
  {
    id: 35,
    name: 'Aleksandr',
    middle_name: 'Popov',
    email: 'aleksandr.popov@gmail.com',
    phone_number: '+375 33 456 78 90',
    group: { id: '35', name: 'K5' },
    active: true
  },
  {
    id: 36,
    name: 'Ksenia',
    middle_name: 'Sokolova',
    email: 'ksenia.sokolova@gmail.com',
    phone_number: '+375 29 123 45 67',
    group: { id: '41', name: 'M5' },
    active: false
  },
  {
    id: 37,
    name: 'Maxim',
    middle_name: 'Petrov',
    email: 'maxim.petrov@gmail.com',
    phone_number: '+375 44 654 32 10',
    group: { id: '6', name: 'A6' },
    active: true
  },
  {
    id: 38,
    name: 'Darya',
    middle_name: 'Ivanova',
    email: 'darya.ivanova@gmail.com',
    phone_number: '+375 25 321 54 87',
    group: { id: '12', name: 'C6' },
    active: true
  },
  {
    id: 39,
    name: 'Vyacheslav',
    middle_name: 'Sidorov',
    email: 'vyacheslav.sidorov@gmail.com',
    phone_number: '+375 33 987 65 43',
    group: { id: '18', name: 'E6' },
    active: true
  },
  {
    id: 40,
    name: 'Ekaterina',
    middle_name: 'Sergeeva',
    email: 'ekaterina.sergeeva@gmail.com',
    phone_number: '+375 29 654 32 10',
    group: { id: '24', name: 'G6' },
    active: true
  },
  {
    id: 41,
    name: 'Ivan',
    middle_name: 'Ivanov',
    email: 'ivan.ivanov@gmail.com',
    phone_number: '+375 44 321 54 87',
    group: { id: '30', name: 'I6' },
    active: true
  },
  {
    id: 42,
    name: 'Olga',
    middle_name: 'Petrova',
    email: 'olga.petrova@gmail.com',
    phone_number: '+375 25 987 65 43',
    group: { id: '36', name: 'K6' },
    active: false
  },
  {
    id: 43,
    name: 'Andrey',
    middle_name: 'Kuznetsov',
    email: 'andrey.kuznetsov@gmail.com',
    phone_number: '+375 33 456 78 90',
    group: { id: '42', name: 'M6' },
    active: true
  },
  {
    id: 44,
    name: 'Mariya',
    middle_name: 'Sidorova',
    email: 'mariya.sidorova@gmail.com',
    phone_number: '+375 29 123 45 67',
    group: { id: '7', name: 'A7' },
    active: true
  },
  {
    id: 45,
    name: 'Dmitriy',
    middle_name: 'Sergeev',
    email: 'dmitriy.sergeev@gmail.com',
    phone_number: '+375 44 654 32 10',
    group: { id: '13', name: 'C7' },
    active: true
  },
  {
    id: 46,
    name: 'Natalya',
    middle_name: 'Ivanova',
    email: 'natalya.ivanova@gmail.com',
    phone_number: '+375 25 321 54 87',
    group: { id: '19', name: 'E7' },
    active: true
  },
  {
    id: 47,
    name: 'Evgeniy',
    middle_name: 'Petrov',
    email: 'evgeniy.petrov@gmail.com',
    phone_number: '+375 33 987 65 43',
    group: { id: '25', name: 'G7' },
    active: false
  },
  {
    id: 48,
    name: 'Anna',
    middle_name: 'Kuznetsova',
    email: 'anna.kuznetsova@gmail.com',
    phone_number: '+375 29 654 32 10',
    group: { id: '31', name: 'I7' },
    active: true
  },
  {
    id: 49,
    name: 'Pavel',
    middle_name: 'Sidorov',
    email: 'pavel.sidorov@gmail.com',
    phone_number: '+375 44 321 54 87',
    group: { id: '37', name: 'K7' },
    active: true
  },
  {
    id: 50,
    name: 'Yelena',
    middle_name: 'Sergeeva',
    email: 'yelena.sergeeva@gmail.com',
    phone_number: '+375 25 987 65 43',
    group: { id: '43', name: 'M7' },
    active: true
  },
  {
    id: 51,
    name: 'Artem',
    middle_name: 'Ivanov',
    email: 'artem.ivanov@gmail.com',
    phone_number: '+375 33 456 78 90',
    group: { id: '8', name: 'A8' },
    active: true
  },
  {
    id: 52,
    name: 'Daria',
    middle_name: 'Petrova',
    email: 'daria.petrova@gmail.com',
    phone_number: '+375 29 123 45 67',
    group: { id: '14', name: 'C8' },
    active: true
  },
  {
    id: 53,
    name: 'Nikita',
    middle_name: 'Kuznetsov',
    email: 'nikita.kuznetsov@gmail.com',
    phone_number: '+375 44 654 32 10',
    group: { id: '20', name: 'E8' },
    active: true
  },
  {
    id: 54,
    name: 'Yuliya',
    middle_name: 'Sidorova',
    email: 'yuliya.sidorova@gmail.com',
    phone_number: '+375 25 321 54 87',
    group: { id: '26', name: 'G8' },
    active: false
  },
  {
    id: 55,
    name: 'Aleksandr',
    middle_name: 'Sergeev',
    email: 'aleksandr.sergeev@gmail.com',
    phone_number: '+375 33 987 65 43',
    group: { id: '32', name: 'I8' },
    active: true
  },
  {
    id: 56,
    name: 'Daria',
    middle_name: 'Ivanova',
    email: 'daria.ivanova@gmail.com',
    phone_number: '+375 29 654 32 10',
    group: { id: '38', name: 'K8' },
    active: true
  },
  {
    id: 57,
    name: 'Vyacheslav',
    middle_name: 'Petrov',
    email: 'vyacheslav.petrov@gmail.com',
    phone_number: '+375 44 321 54 87',
    group: { id: '44', name: 'M8' },
    active: true
  },
  {
    id: 58,
    name: 'Ekaterina',
    middle_name: 'Kuznetsova',
    email: 'ekaterina.kuznetsova@gmail.com',
    phone_number: '+375 25 987 65 43',
    group: { id: '9', name: 'A9' },
    active: true
  },
  {
    id: 59,
    name: 'Ivan',
    middle_name: 'Sidorov',
    email: 'ivan.sidorov@gmail.com',
    phone_number: '+375 33 456 78 90',
    group: { id: '15', name: 'C9' },
    active: true
  },
  {
    id: 60,
    name: 'Olga',
    middle_name: 'Sergeeva',
    email: 'olga.sergeeva@gmail.com',
    phone_number: '+375 29 123 45 67',
    group: { id: '21', name: 'E9' },
    active: true
  },
  {
    id: 61,
    name: 'Maksim',
    middle_name: 'Ivanov',
    email: 'maksim.ivanov@gmail.com',
    phone_number: '+375 44 654 32 10',
    group: { id: '27', name: 'G9' },
    active: false
  },
  {
    id: 62,
    name: 'Alina',
    middle_name: 'Petrova',
    email: 'alina.petrova@gmail.com',
    phone_number: '+375 25 321 54 87',
    group: { id: '33', name: 'I9' },
    active: false
  },
  {
    id: 63,
    name: 'Egor',
    middle_name: 'Kuznetsov',
    email: 'egor.kuznetsov@gmail.com',
    phone_number: '+375 33 987 65 43',
    group: { id: '39', name: 'K9' },
    active: true
  },
  {
    id: 64,
    name: 'Polina',
    middle_name: 'Sidorova',
    email: 'polina.sidorova@gmail.com',
    phone_number: '+375 29 654 32 10',
    group: { id: '45', name: 'M9' },
    active: true
  },
  {
    id: 65,
    name: 'Timofey',
    middle_name: 'Sergeev',
    email: 'timofey.sergeev@gmail.com',
    phone_number: '+375 44 321 54 87',
    group: { id: '10', name: 'B0' },
    active: true
  },
  {
    id: 66,
    name: 'Darya',
    middle_name: 'Ivanova',
    email: 'darya.ivanova@gmail.com',
    phone_number: '+375 25 987 65 43',
    group: { id: '16', name: 'D0' },
    active: true
  },
  {
    id: 67,
    name: 'Artem',
    middle_name: 'Petrov',
    email: 'artem.petrov@gmail.com',
    phone_number: '+375 33 456 78 90',
    group: { id: '22', name: 'F0' },
    active: true
  },
  {
    id: 68,
    name: 'Ksenia',
    middle_name: 'Kuznetsova',
    email: 'ksenia.kuznetsova@gmail.com',
    phone_number: '+375 29 123 45 67',
    group: { id: '28', name: 'H0' },
    active: true
  },
  {
    id: 69,
    name: 'Yegor',
    middle_name: 'Sidorov',
    email: 'yegor.sidorov@gmail.com',
    phone_number: '+375 44 654 32 10',
    group: { id: '34', name: 'J0' },
    active: true
  },
  {
    id: 70,
    name: 'Mariya',
    middle_name: 'Sergeeva',
    email: 'mariya.sergeeva@gmail.com',
    phone_number: '+375 25 321 54 87',
    group: { id: '40', name: 'L0' },
    active: true
  },
  {
    id: 71,
    name: 'Nikita',
    middle_name: 'Ivanov',
    email: 'nikita.ivanov@gmail.com',
    phone_number: '+375 33 987 65 43',
    group: { id: '46', name: 'N0' },
    active: false
  },
  {
    id: 72,
    name: 'Daria',
    middle_name: 'Petrova',
    email: 'daria.petrova@gmail.com',
    phone_number: '+375 29 654 32 10',
    group: { id: '11', name: 'B1' },
    active: true
  },
  {
    id: 73,
    name: 'Maksim',
    middle_name: 'Kuznetsov',
    email: 'maksim.kuznetsov@gmail.com',
    phone_number: '+375 44 321 54 87',
    group: { id: '17', name: 'D1' },
    active: false
  },
  {
    id: 74,
    name: 'Alina',
    middle_name: 'Sidorova',
    email: 'alina.sidorova@gmail.com',
    phone_number: '+375 25 987 65 43',
    group: { id: '23', name: 'F1' },
    active: true
  },
  {
    id: 75,
    name: 'Egor',
    middle_name: 'Sergeev',
    email: 'egor.sergeev@gmail.com',
    phone_number: '+375 33 456 78 90',
    group: { id: '29', name: 'H1' },
    active: true
  },
  {
    id: 76,
    name: 'Polina',
    middle_name: 'Ivanova',
    email: 'polina.ivanova@gmail.com',
    phone_number: '+375 29 123 45 67',
    group: { id: '35', name: 'J1' },
    active: true
  },
  {
    id: 77,
    name: 'Timofey',
    middle_name: 'Petrov',
    email: 'timofey.petrov@gmail.com',
    phone_number: '+375 44 654 32 10',
    group: { id: '41', name: 'L1' },
    active: true
  },
  {
    id: 78,
    name: 'Darya',
    middle_name: 'Kuznetsova',
    email: 'darya.kuznetsova@gmail.com',
    phone_number: '+375 25 321 54 87',
    group: { id: '47', name: 'N1' },
    active: true
  },
  {
    id: 79,
    name: 'Artem',
    middle_name: 'Sidorov',
    email: 'artem.sidorov@gmail.com',
    phone_number: '+375 33 987 65 43',
    group: { id: '12', name: 'B2' },
    active: true
  },
  {
    id: 80,
    name: 'Ksenia',
    middle_name: 'Sergeeva',
    email: 'ksenia.sergeeva@gmail.com',
    phone_number: '+375 29 654 32 10',
    group: { id: '18', name: 'D2' },
    active: false
  }
]

export const GRADEBOOKS = [
  {
    id: 1,
    name: ' IELTS1',
    period: '2024-03-11 - 2024-06-07',
    lessons: []
  },
  {
    id: 2,
    name: ' IELTS2',
    period: '2024-03-11 - 2024-06-07',
    lessons: []
  },
  {
    id: 3,
    name: ' IELTS3',
    period: '2024-03-11 - 2024-06-07',
    lessons: []
  },
  {
    id: 4,
    name: ' IELTS4',
    period: '2024-03-11 - 2024-06-07',
    lessons: []
  },
  {
    id: 5,
    name: ' IELTS5',
    period: '2024-03-11 - 2024-06-07',
    lessons: []
  },
  {
    id: 6,
    name: ' IELTS6',
    period: '2024-03-11 - 2024-06-07',
    lessons: []
  },
  {
    id: 7,
    name: ' IELTS7',
    period: '2024-03-11 - 2024-06-07',
    lessons: []
  },
  {
    id: 8,
    name: ' IELTS8',
    period: '2024-03-11 - 2024-06-07',
    lessons: []
  }
]

export const OFFICES = [
  { id: 1, name: 'пр-т Машерова, 10-306' },
  { id: 2, name: 'ул Мележа, 1-533' },
  { id: 3, name: 'ул Смолячкова, 9-430' },
  { id: 4, name: 'ул Притыцкого, 87' },
  { id: 5, name: 'ул Жиновича, 11' }
]

export const SEMESTERS = [
  {
    id: 1,
    office: { id: 1, name: 'пр-т Машерова, 10-306' },
    group: { id: '22', name: 'H1' },
    period: '2024-03-11 - 2024-06-07'
  },
  {
    id: 2,
    office: { id: 5, name: 'ул Жиновича, 11' },
    group: { id: '6', name: 'B3' },
    period: '2024-03-11 - 2024-06-07'
  },
  {
    id: 3,
    office: { id: 3, name: 'ул Смолячкова, 9-430' },
    group: { id: '7', name: 'C1' },
    period: '2024-03-11 - 2024-06-07'
  },
  {
    id: 4,
    office: { id: 5, name: 'ул Жиновича, 11' },
    group: { id: '1', name: 'A1' },
    period: '2024-03-11 - 2024-06-07'
  }
]

export const QUARTERS = [
  {
    id: 1,
    name: 'за I и II четверти 2024'
  },
  {
    id: 2,
    name: 'за III и IV четверти 2024'
  },
  {
    id: 3,
    name: 'за 1 семестр 2024'
  },
  {
    id: 4,
    name: 'за 2 семестр 2024'
  },
  {
    id: 5,
    name: 'за 3 семестр 2024'
  },
  {
    id: 6,
    name: 'за 4 семестр 2024'
  }
]

export const STUDENTS_REPORTS = [
  {
    id: 1,
    name: 'Dmitry',
    middle_name: 'Govorko',
    group: { id: '42', name: 'N3' },
    semester: {
      id: 1,
      name: 'за I и II четверти 2024'
    }
  },
  {
    id: 2,
    name: 'Olga',
    middle_name: 'Petrova',
    group: { id: '1', name: 'A1' },
    semester: {
      id: 1,
      name: 'за I и II четверти 2024'
    }
  },
  {
    id: 3,
    name: 'Ivan',
    middle_name: 'Ivanov',
    group: { id: '7', name: 'C1' },
    semester: {
      id: 2,
      name: 'за III и IV четверти 2024'
    }
  },
  {
    id: 4,
    name: 'Anastasia',
    middle_name: 'Sidorova',
    group: { id: '13', name: 'E1' },
    semester: {
      id: 1,
      name: 'за I и II четверти 2024'
    }
  },
  {
    id: 5,
    name: 'Andrey',
    middle_name: 'Sergeev',
    group: { id: '19', name: 'G1' },
    semester: {
      id: 2,
      name: 'за III и IV четверти 2024'
    }
  },
  {
    id: 6,
    name: 'Maria',
    middle_name: 'Kuznetsova',
    group: { id: '25', name: 'I1' },
    semester: {
      id: 2,
      name: 'за III и IV четверти 2024'
    }
  },
  {
    id: 7,
    name: 'Alexander',
    middle_name: 'Popov',
    group: { id: '31', name: 'K1' },
    semester: {
      id: 1,
      name: 'за I и II четверти 2024'
    }
  },
  {
    id: 8,
    name: 'Daria',
    middle_name: 'Sokolova',
    group: { id: '37', name: 'M1' },
    semester: {
      id: 1,
      name: 'за I и II четверти 2024'
    }
  },
  {
    id: 9,
    name: 'Maxim',
    middle_name: 'Petrov',
    group: { id: '2', name: 'A2' },
    semester: {
      id: 2,
      name: 'за III и IV четверти 2024'
    }
  },
  {
    id: 10,
    name: 'Ksenia',
    middle_name: 'Ivanova',
    group: { id: '8', name: 'C2' },
    semester: {
      id: 1,
      name: 'за I и II четверти 2024'
    }
  }
]

export const GRADES = [
  { id: 1, name: 'A' },
  { id: 2, name: 'B' },
  { id: 3, name: 'C' },
  { id: 4, name: 'D' },
  { id: 5, name: 'E' }
]

export const WEEK = [
  { id: 1, name: 'Понедельник' },
  { id: 2, name: 'Вторник' },
  { id: 3, name: 'Среда' },
  { id: 4, name: 'Четверг' },
  { id: 5, name: 'Пятница' },
  { id: 6, name: 'Суббота' },
  { id: 7, name: 'Воскресенье' }
]
