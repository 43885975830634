import { Box, TextField, Typography } from '@mui/material'
import React from 'react'
import s from './Style.module.css'

function BasicInput({ label, value }) {
  const [isTextFieldFocused, setIsTextFieldFocused] = React.useState(false)

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Typography sx={{ marginBottom: '10px', color: 'grey' }}>{label}</Typography>
        <TextField
          value={value}
          className={s.custom}
          sx={{
            width: '100%',
            '& .MuiOutlinedInput-root': {
              '&.Mui-focused fieldset': {
                border: '2px solid #212636'
              }
            }
          }}
        />
      </Box>
    </>
  )
}

export default BasicInput
